<template>
  <div class="login">
    <div class="millibase-logo">
      <i class="fa fa-diamond"></i> millibase.net
    </div>
    <demo-warning></demo-warning>
    <page-header title="Connexion Azure"></page-header>
  </div>
</template>

<script>
// @ is an alias to /src

import { mapActions } from 'vuex'
import DemoWarning from '@/components/Layout/DemoWarning'
import PageHeader from '@/components/Layout/PageHeader'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'azure-login',
  components: {
    DemoWarning,
    PageHeader,
  },
  mixins: [BackendMixin],
  data() {
    return {
    }
  },
  async created() {
  },
  computed: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
  },
}
</script>

<style lang="less">
</style>
